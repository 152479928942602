.novedad-row {
  transition: background-color 0.3s ease;
}

.novedad-row:hover {
  background-color: #f5f5f5;
}

.novedad-collapse {
  background-color: #fafafa;
  border-left: 5px solid #1976d2;
  padding: 16px;
  border-radius: 4px;
  margin: 8px 0;
}

.novedad-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.novedad-info {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.novedad-info strong {
  margin-right: 8px;
}
