/* LoginForm.css */
.login-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: linear-gradient(to bottom right, #0599ee, #0a2e5c); /* Gradient background */
}

.login-logo {
    width: 300px;
    margin-bottom: 10px;
}

.login-form-container {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    margin-bottom: 100px;
}

.login-form {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-input {
    width: 300px; /* Ancho uniforme para todos los inputs */
    margin: 10px 0;
    padding: 10px;
    padding-right: 40px; /* Espacio adicional para el ícono en el input de contraseña */
    border: 1px solid #ccc;
    border-radius: 5px;
    position: relative; /* Añade posición relativa para poder posicionar el ícono de ocultar contraseña */
}

.login-button {
    width: 200px;
    margin-top: 20px;
    padding: 10px;
    border: none;
    border-radius: 5px;
    background-color: #6cb2eb;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover {
    background-color: #2ca75b; /* Darker color on hover */
}

.password-container {
    position: relative;
}

.password-input {
    padding-right: 40px; /* Asegura que el ícono no cubra el texto */
}

.password-icon {
    position: absolute;
    right: 10px; /* Ajusta la posición del ícono dentro del input */
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

/* Media queries para dispositivos más pequeños */
@media (max-width: 600px) {
    .login-logo {
        width: 200px; /* Reducir el tamaño del logo */
        margin-bottom: 5px;
    }

    .login-input {
        width: 250px; /* Reducir el ancho de los inputs */
        padding: 8px;
        padding-right: 35px; /* Ajustar el espacio para el ícono */
    }

    .password-icon {
        right: 5px; /* Ajustar la posición del ícono más cerca del borde */
    }

    .login-form-container {
        padding: 15px; /* Menor padding en el contenedor */
        margin-bottom: 50px; /* Menor margen en la parte inferior */
    }

    .login-button {
        width: 150px; /* Botón más pequeño */
        margin-top: 15px;
        padding: 8px;
    }
}
