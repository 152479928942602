/* src/components/Authorizations/AuthorizationCard.css */

/* Estilo base para la tarjeta de autorización */
.authorization-card {
  transition: border-left 0.3s ease, background-color 0.3s ease;
  cursor: pointer;
  margin-bottom: 0; /* Eliminado o reducido a 0 */
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border-left-width: 4px;
  border-left-style: solid;
  border-radius: 8px;
  position: relative; /* Necesario para posicionar las acciones */
}

/* Clases dinámicas para el borde según el estado */
.border-activa {
  border-left-color: #2f54eb;
}

.border-suspendida {
  border-left-color: #fa541c;
}

.border-completada {
  border-left-color: #52c41a;
}

.border-default {
  border-left-color: #d9d9d9;
}

/* Estilo para cuando la tarjeta está seleccionada */
.authorization-card.selected {
  background-color: #f0f0f0;
}

/* Estilos para las filas de información dentro de la tarjeta */
.card-row {
  margin-bottom: 8px; /* Reducido de 16px a 8px */
}

.authorization-info {
  display: flex;
  align-items: center;
  margin-bottom: 8px; /* Espacio entre elementos */
  font-size: 0.95rem; /* Legibilidad */
}

.authorization-info strong {
  margin-right: 8px; /* Separación entre label y contenido */
  color: #595959;
  font-size: 0.95rem;
}

.authorization-icon {
  margin-right: 8px;
  font-size: 1.4rem; /* Tamaño de iconos */
  transition: color 0.3s;
}

.authorization-card:hover .authorization-icon {
  color: #40a9ff;
}

/* Alineación a la derecha para ciertos elementos */
.authorization-info.right-aligned {
  justify-content: flex-end;
}

/* Estilo para las etiquetas de estado */
.status-tag {
  margin-bottom: 8px; /* Reducido de 12px a 8px */
  font-size: 0.85rem;
  border-radius: 4px;
  padding: 4px 10px;
}

/* Estilo para información adicional de transferencia */
.transfer-info {
  font-size: 0.85rem;
}

/* Estilo para información de copago */
.copago-info {
  font-size: 0.85rem;
  color: #52c41a;
}

/* Estilo para el texto de observaciones */
.observations-text {
  font-size: 0.9rem;
  margin-top: 12px;
}

/* Estilos para los botones de acción */
.authorization-actions {
  display: flex;
  justify-content: center;
  margin-top: 16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.authorization-card.selected .authorization-actions {
  opacity: 1;
}

.authorization-actions .ant-btn {
  margin-left: 10px;
  padding: 8px;
  font-size: 0.9rem;
}

/* Estilos para la alerta de observaciones */
.observations-alert {
  font-size: 14px;
  padding: 12px 16px;
  margin-top: 12px;
}

.observations-alert .ant-alert-icon {
  font-size: 18px;
}

.observations-alert .ant-alert-description {
  margin-left: 12px;
}

/* Estilos Responsivos */

/* Tablets y dispositivos medianos */
@media (max-width: 768px) {
  .authorization-card {
    padding: 20px; /* Ajuste de padding */
    margin-bottom: 8px; /* Consistente con el cambio principal */
  }

  .card-row {
    margin-bottom: 8px; /* Consistente con el cambio principal */
  }

  .authorization-info {
    font-size: 0.9rem;
    margin-bottom: 10px;
  }

  .authorization-info strong {
    margin-right: 6px;
    font-size: 0.9rem;
  }

  .authorization-icon {
    font-size: 1.2rem;
  }

  .authorization-actions .ant-btn {
    margin-left: 8px;
    padding: 6px;
  }

  .status-tag {
    margin-bottom: 8px; /* Consistente con el cambio principal */
    font-size: 0.8rem;
    padding: 3px 8px;
  }

  .observations-alert {
    font-size: 13px;
    padding: 10px 14px;
    margin-top: 10px;
  }

  .observations-alert .ant-alert-icon {
    font-size: 16px;
  }

  .observations-alert .ant-alert-description {
    margin-left: 10px;
  }
}

/* Dispositivos móviles */
@media (max-width: 576px) {
  .authorization-card {
    padding: 16px; /* Ajuste de padding */
    margin-bottom: 8px; /* Consistente con el cambio principal */
  }

  .card-row {
    margin-bottom: 8px; /* Consistente con el cambio principal */
  }

  .authorization-info {
    font-size: 0.85rem;
    margin-bottom: 8px;
  }

  .authorization-info strong {
    margin-right: 4px;
    font-size: 0.85rem;
  }

  .authorization-icon {
    font-size: 1rem;
  }

  .authorization-actions .ant-btn {
    margin-left: 6px;
    padding: 5px;
  }

  .status-tag {
    margin-bottom: 8px; /* Consistente con el cambio principal */
    font-size: 0.75rem;
    padding: 2px 6px;
  }

  .observations-alert {
    font-size: 12px;
    padding: 8px 12px;
    margin-top: 8px;
  }

  .observations-alert .ant-alert-icon {
    font-size: 14px;
  }

  .observations-alert .ant-alert-description {
    margin-left: 8px;
  }
}
