/* src/components/FullscreenLoader.css */

/* Definir la animación de giro */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Estilos para el overlay de carga */
.fullscreen-loader-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.8); /* Fondo semi-transparente */
  z-index: 1000; /* Asegura que el loader esté por encima de otros elementos */
}

/* Estilos para el spinner */
.fullscreen-loader-spinner {
  width: 60px;
  height: 60px;
  border: 8px solid #f3f3f3; /* Borde gris claro */
  border-top: 8px solid #1890ff; /* Borde superior azul (color primario de Ant Design) */
  border-radius: 50%;
  animation: spin 1s linear infinite; /* Animación de rotación continua */
}

/* Estilos para el mensaje de carga */
.fullscreen-loader-message {
  margin-top: 20px;
  font-size: 1.2rem;
  color: #555; /* Color del texto del mensaje */
}
