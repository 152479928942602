/* src/Components/modules/Authorizations/TrasladoDialog.css */

/* Estilos para el encabezado del Modal */
.traslado-modal-header {
  background: linear-gradient(90deg, #0a2e5c, #004d99);
  padding: 12px;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 20px;
  text-align: center;
}

/* Estilos para el contenido del Modal */
.traslado-modal-content {
  background-color: #f9f9f9;
  padding: 16px;
  border-radius: 8px;
}

/* Estilos para las secciones */
h4.traslado-section-title {
  margin-bottom: 16px;
  background-color: #0a2e5c;
  color: white;
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
}
/* Estilos para los iconos de las secciones */
.traslado-section-icon {
  margin-right: 8px;
}

/* Estilos para los Selects y AutoComplete */
.traslado-select,
.traslado-autocomplete {
  width: 100%;
  border-color: #0a2e5c;
}

/* Estilos para los DatePickers y TimePickers */
.traslado-datepicker,
.traslado-timepicker {
  width: 100%;
}

/* Estilos para las tarjetas */
.traslado-cards-row {
  margin-bottom: 16px;
}

.traslado-card {
  background-color: #f0f0f0;
  border-color: #d9d9d9;
  text-align: center;
  border-radius: 8px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.1);
  padding: 8px;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  font-size: 14px;
}

.traslado-card:hover {
  transform: translateY(-4px);
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.2);
}

.traslado-card-icon {
  margin-right: 4px;
  font-size: 16px;
}

.traslado-card-text {
  font-size: 16px;
  font-weight: bold;
  margin: 0;
}

/* Estilos para las columnas de intercambio de origen y destino */
.traslado-swap-col {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 50px;
}

/* Estilos para los botones del Modal */
.traslado-button {
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.agendar-button {
  background-color: #ffa500;
  color: white;
  border-color: #ffa500;
}

.agendar-button:hover {
  background-color: #ff8c00;
}

.cancelar-button {
  background-color: #d32f2f;
  color: white;
  border-color: #d32f2f;
}

.cancelar-button:hover {
  background-color: #b71c1c;
}

.programar-button {
  background-color: #0a2e5c;
  color: white;
  border-color: #0a2e5c;
}

.programar-button:hover {
  background-color: #004080;
}

/* Estilos para el botón de ver ruta en el mapa */
.traslado-map-button {
  margin-top: 16px;
  background-color: #0a2e5c;
  color: white;
}

.traslado-map-button:hover {
  background-color: #004080;
}

/* Estilos para el modal responsivo */
@media (max-width: 768px) {
  .traslado-modal-header {
    font-size: 18px;
    padding: 10px;
  }

  .traslado-section-title {
    font-size: 16px;
    padding: 6px;
  }

  .traslado-section-icon {
    margin-right: 6px;
  }

  .traslado-card {
    padding: 6px;
  }

  .traslado-card-text {
    font-size: 14px;
  }
}
