/* src/Components/modules/authorizations/Form/AuthorizationDates.css */

.selected-day {
  background-color: #1890ff; /* Azul de Ant Design */
  color: white;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
}
