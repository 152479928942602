/* src/components/Modules.css */

.custom-layout {
  background-color: #0a2e5c; /* Cambia este color según tus necesidades */
}

.pac-container {
  z-index: 1200; /* Ajusta este valor para que sea mayor que el z-index del modal */
}

.site-layout {
  transition: margin-left 0.2s;
}

.footer {
  text-align: center;
}

/* src/components/Modules.css */

/* Ajusta el color y ancho de la barra */
.simplebar-scrollbar::before {
  background-color: #888;
}

.simplebar-scrollbar:hover::before {
  background-color: #555;
}

.simplebar-track {
  background: #f0f0f0;
}
