/* BulkUpload.css */

/* Estilo para los contenedores de errores */
.error-container {
  max-height: 200px;
  overflow-y: auto;
  padding: 10px;
  border: 1px solid #f5222d;
  border-radius: 4px;
  background-color: #fff1f0;
  margin-bottom: 10px;
}

/* Estilo para los contenedores de éxito */
.success-container {
  padding: 10px;
  border: 1px solid #52c41a;
  border-radius: 4px;
  background-color: #f6ffed;
  margin-bottom: 10px;
}

/* Estilo para botones personalizados */
.custom-button {
  height: 50px;
  font-size: 16px;
}

/* Ajustes para mejorar la apariencia en dispositivos móviles */
@media (max-width: 768px) {
  .ant-card {
    padding: 20px;
  }

  .ant-row {
    flex-direction: column;
  }

  .ant-col {
    width: 100% !important;
  }
}
