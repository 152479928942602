/* src/components/Authorizations/Authorizations.css */

/* Contenedor principal de autorizaciones */
.authorizations-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: #ffffff; /* Fondo suave para diferenciar */
}

/* Área que contiene la lista de autorizaciones */
.authorizations-list {
  flex: 1;
  overflow: hidden; /* Evita scroll interno innecesario */
  padding: 16px;
  background-color: #ffffff; /* Fondo consistente */
}

/* Estilo para el spinner de carga */
.loading-spinner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 18px;
  color: #1677ff;
}

/* Área del footer que contiene la paginación y estadísticas */
.authorizations-footer {
  margin-top: 16px;
}

/* Estilos para el título de los modales */
.modal-title {
  background-color: #0a2e5c;
  color: #fff;
  padding: 12px 20px;
  font-size: 20px;
  font-weight: bold;
  position: -webkit-sticky; /* Soporte para Safari */
  position: sticky;
  top: 0;
  z-index: 1;
  border-bottom: 1px solid #e8e8e8;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Contenido dentro de los modales */
.modal-content {
  max-height: calc(80vh - 60px);
  overflow-y: auto;
  padding: 16px;
}

/* Estilos para la lista virtualizada de react-window */
.react-window-list {
  display: flex;
  flex-direction: column;
}

/* Estilos para el pie de página de la paginación y tags */
.authorizations-footer .ant-card-body {
  padding: 16px;
}

/* Estilos Responsivos */

/* Tablets y dispositivos medianos */
@media (max-width: 768px) {
  .authorizations-container {
    padding: 12px;
  }

  .authorizations-list {
    flex: 1;
    overflow: hidden; /* Evita scroll interno innecesario */
    padding: 8px; /* Reducido de 16px a 8px */
    background-color: #ffffff; /* Fondo consistente */
  }

  .loading-spinner {
    font-size: 16px;
  }

  .modal-title {
    font-size: 18px;
    padding: 10px 16px;
  }

  .modal-content {
    padding: 12px;
  }
}

/* Dispositivos móviles */
@media (max-width: 576px) {
  .authorizations-container {
    padding: 8px;
  }

  .authorizations-list {
    padding: 8px;
  }

  .loading-spinner {
    font-size: 14px;
  }

  .modal-title {
    font-size: 16px;
    padding: 8px 12px;
  }

  .modal-content {
    padding: 8px;
  }
}
