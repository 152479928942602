/* src/components/Sidebar.css */

.custom-drawer {
  background-color: #001529;
}

.custom-drawer .ant-drawer-body {
  padding: 0;
  background-color: #001529;
}

.custom-drawer .ant-drawer-header {
  background-color: #001529;
}

.custom-drawer-content-wrapper {
  width: 250px;
}
