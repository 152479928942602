/* Institutions.css */

.institution-card {
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.institution-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.12), 0 6px 6px rgba(0, 0, 0, 0.12);
}
