/* Personalizar scrollbar en navegadores Webkit */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}

/* Personalizar scrollbar en navegadores compatibles */
@supports (scrollbar-width: thin) {
  * {
    scrollbar-width: thin; /* Opciones: auto, thin, none */
    scrollbar-color: #888 #f1f1f1; /* color del thumb y del track */
  }
}
