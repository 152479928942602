.CenterControl-content {
  padding: 0;
  position: relative;
}

/* Mantiene los tabs y el botón fijos en la parte superior */
.sticky-header {
  position: -webkit-sticky; /* Safari */
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1000; /* Para que quede sobre otros elementos */
  padding: 10px 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Añade una sombra */
}

/* Lista de traslados para que tenga scroll */
.traslados-list {
  max-height: 62vh; /* Ajusta la altura de la lista */
  overflow-y: auto;
}

.traslados-item {
  position: relative;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
  margin-bottom: 20px;
  padding: 0px;
  display: flex;
  flex-direction: column;
}

/* Contenedor para CS- id y estado */
.traslados-item-id-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  background-color: #6200ea;
  color: white;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.traslados-item-id {
  flex: 1;
  text-align: justify;
}

.traslados-item-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
}

.traslados-item-destination {
  margin-top: 10px;
  text-align: center;
}

.traslados-item-footer {
  padding: 0 20px 10px 20px;
}
