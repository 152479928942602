.authorization-loading {
  text-align: center;
  padding: 20px;
}

.authorization-content {
  padding: 12px;
}

/* Mantén los estilos existentes */
.traslado-day {
  width: 100%;
  height: 100%;
  background-color: #52c41a; /* Verde para múltiples traslados */
  display: flex;
  transition: background-color 0.3s ease;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  position: relative;
}

.traslado-day:hover {
  background-color: #73d13d;
  cursor: pointer;
}

.traslado-tooltip {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
