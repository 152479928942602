/* src/components/Authorizations/Filters.css */

.filters-container {
  margin-bottom: 24px;
  padding: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.filters-row {
  /* Ya no es necesario margin-bottom aquí */
}

.filters-input,
.filters-select,
.filters-range-picker {
  width: 100%;
  font-size: 0.85rem; /* Reducido para hacerlos más compactos */
}

.filters-button {
  /* Se eliminó margin-left para evitar conflicto con Space */
  border-radius: 4px;
  font-size: 0.85rem; /* Reducido para hacerlos más compactos */
  padding: 6px 12px; /* Reducido para hacerlos más pequeños */
  width: 100%; /* Asegura que el botón ocupe todo el ancho dentro del Space vertical */
}

/* Ajustes específicos para Inputs y Selects */
.filters-input .ant-input {
  height: 32px; /* Reducido */
  font-size: 0.85rem;
}

.filters-select .ant-select-selector {
  height: 32px; /* Reducido */
  font-size: 0.85rem;
}

.filters-range-picker .ant-picker {
  height: 32px; /* Reducido */
  font-size: 0.85rem;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .filters-container {
    padding: 12px;
  }

  .filters-button {
    /* Se eliminó margin-left para evitar conflicto con Space */
    font-size: 0.8rem; /* Más pequeño en tablets */
    padding: 5px 10px;
  }

  .filters-input,
  .filters-select,
  .filters-range-picker {
    font-size: 0.8rem;
  }
}

@media (max-width: 576px) {
  .filters-container {
    padding: 8px;
  }

  .filters-button {
    /* Se eliminó margin-left para evitar conflicto con Space */
    font-size: 0.75rem; /* Más pequeño en móviles */
    padding: 4px 8px;
  }

  .filters-input,
  .filters-select,
  .filters-range-picker {
    font-size: 0.75rem;
  }

  /* Asegura que el RangePicker ocupe todo el ancho disponible */
  .filters-range-picker {
    width: 100%;
  }

  /* Opcional: Centrar los botones en pantallas pequeñas */
  .filters-row > .ant-col-lg-4 {
    text-align: center;
    margin-top: 8px;
  }
}
